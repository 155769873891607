// TODO: Batch uploader integrator for hospitals.
import WebWorkerController from '@controllers/WebWorkerController';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
});

// Call it just to clean the database.
WebWorkerController.getInstance();
